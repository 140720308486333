//SLIDER
;
(function() {	
	
	var btnBack = document.getElementById('btnBack'),
			btnNext = document.getElementById('btnNext');
	
	if (!btnBack && !btnNext) {
		console.log('slider not found');
		return;
	}
	
	var slideIndex = 1;
	
	showSlides(slideIndex);		

	btnBack.addEventListener('click', plusSlides.bind(null, -1), false);
	btnNext.addEventListener('click', plusSlides.bind(null, 1), false);
	
	function plusSlides(n, e) {
		e.preventDefault();
		
		showSlides(slideIndex, slideIndex += n);
	}

//	function currentSlide(n) {
//		showSlides(slideIndex = n);
//	}

	function showSlides(prev, n) {
		var i;
		var slides = document.getElementsByClassName("js-pro-slider__slide"),
				images = document.getElementsByClassName("js-pro-slider__img");
		
		console.log(slides);
		console.log(n);
		if (slides.length == 1) {
			if (n > images.length) {slideIndex = 1}    
			if (n < 1) {slideIndex = images.length}

			for (i = 0; i < images.length; i++) {
					images[i].style.display = "none";  
			}

			if (prev < n) {
				images[slideIndex-1].classList.remove('hide');			
				images[slideIndex-1].classList.add('show');
			} else if (prev > n) {
				images[slideIndex-1].classList.remove('show');
				images[slideIndex-1].classList.add('hide');			
			}
			images[slideIndex-1].style.display = "block"; 
			
		} else if (slides.length > 1) {
			
			if (n > slides.length) {slideIndex = 1}    
			if (n < 1) {slideIndex = slides.length}

			for (i = 0; i < slides.length; i++) {
					slides[i].style.display = "none";  
					images[i].style.display = "none";  
			}

			if (prev < n) {
				slides[slideIndex-1].classList.remove('hide');			
				images[slideIndex-1].classList.remove('hide');			
				slides[slideIndex-1].classList.add('show');
				images[slideIndex-1].classList.add('show');
			} else if (prev > n) {
				slides[slideIndex-1].classList.remove('show');
				images[slideIndex-1].classList.remove('show');
				slides[slideIndex-1].classList.add('hide');			
				images[slideIndex-1].classList.add('hide');			
			}
			slides[slideIndex-1].style.display = "block";  
			images[slideIndex-1].style.display = "block"; 
		}
		
		
		
	}	
	
})();


//МОДАЛЬНОЕ ОКНО
;
(function() {
	
		// forEach method, could be shipped as part of an Object Literal/Module
	var forEach = function (array, callback, scope) {
		for (var i = 0; i < array.length; i++) {
			callback.call(scope, i, array[i]); // passes back stuff we need
		}
	};
	
			// Get the modal
	var arrBtnOrder = document.getElementsByClassName('js-btn-callback');
	
	var modalOrder = document.getElementById('modalCall');
	var btnShowModalCall = document.getElementById('btnShowModalCall');

	// Get the <span> element that closes the modal
	var btnsCloseModal = document.getElementsByClassName("c-modal-call__btn-close");
	
	
	// When the user clicks the button, open the modal 
	function showModal(array, modal) {
		forEach(array, function(i, item) {			
			item.onclick = function(e) {
				e.preventDefault();
				document.body.style.overflow = "hidden";
				modal.style.display = "block";
				modal.dataset.visible = "true";
			}
		});
	}
	
	showModal(arrBtnOrder, modalOrder);


	// When the user clicks on <span> (x), close the modal
	for (var i = 0; i < btnsCloseModal.length; i++) {
		btnsCloseModal[i].onclick = function() {
			var currentModal = document.querySelector('.c-modal-call[data-visible="true"]');
			currentModal.style.display = "none";
			currentModal.dataset.visible = "false";
			document.body.style.overflow = "auto";
		}
	}

	// When the user clicks anywhere outside of the modal, close it
	window.onclick = function(event) {
		if (event.target == modalOrder) {
			modalOrder.style.display = "none";
			modalOrder.dataset.visible = "false";
			document.body.style.overflow = "auto";

		}
	}	
	
	var btnSubmit = document.getElementById('btnSubmit');
	
	btnSubmit.addEventListener('click', function(e) {
		e.preventDefault();
				
		var modalBody = document.querySelector('.c-modal-call__body');
		var name = document.getElementById('name');
		var phone = document.getElementById('phone');
		var callme = document.getElementById('callme');
		
		var xhr = new XMLHttpRequest();
		var params = 'callme=' + encodeURIComponent(callme.getAttribute('name')) + '&name=' + encodeURIComponent(name.value) + '&phone=' + encodeURIComponent(phone.value) + encodeURIComponent(comment.value) + '&comment=' + encodeURIComponent(comment.value);
			
		xhr.open('GET', 'mail.php?' + params, true);
		xhr.onreadystatechange = function() {
			if (this.readyState != 4) return;
			
			if (this.status != 200) {
				alert('Ошибка! Невожможно отправить данные.');				
			} else {
				console.log(this.responseText);
			}
		}
		
		xhr.send();
		
		modalBody.innerHTML = "<div class='c-preloader'><div class='c-preloader__dots'><div class='c-preloader__dot'></div><div class='c-preloader__dot'></div><div class='c-preloader__dot'></div><div class='c-preloader__dot'></div><div class='c-preloader__dot'></div></div></div>";		
		
		setTimeout(function() {
			modalBody.innerHTML = '<h2>Спасибо за ваш заказ!</h2><p>Мы свяжемся с вами в ближайшее время!</p>';
		}, 4444);		
	});
	
})();


//ANIMATIONS
;
(function() {
	
	var bottomGap = 260;
	
	if (document.documentElement.clientWidth < 450) {
		bottomGap = 0;
	}
	
	var elems = document.getElementsByClassName('h-animation');
	
	for (var i = 0; i < elems.length; i++) {
		var elem = elems[i];
		elem.classList.add('js-hidden');
		setAnimation(elem);
	}
				
	window.addEventListener('scroll', function() {
		
		for (var i = 0; i < elems.length; i++) {
			var elem = elems[i];
			setAnimation(elem);
		}
		
	}, false)
	
	function getCoords(elem) {
		var box = elem.getBoundingClientRect();
		
		var body = document.body;
		var docEl = document.documentElement;
		
		var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
		var scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;
		
		var clientTop = docEl.clientTop || body.clientTop || 0;
		var clientLeft = docEl.clientLeft || body.clienLeft || 0;
		
		var top = box.top + scrollTop - clientTop;
		var left = box.left + scrollLeft - clientLeft;
		
		return {
			top: top,
			left: left
		};
	}
	
	function setAnimation(elem) {
		var coordsElem = getCoords(elem),
				scrollTop = window.pageYOffset || document.documentElement.scrollTop;

		if (coordsElem.top < (scrollTop + document.documentElement.clientHeight - bottomGap)) {
			elem.classList.remove('js-hidden');

			switch(elem.dataset.animation) {
				case 'toRight':
					elem.classList.add('js-toRight');
					break;
				case 'toLeft':
					elem.classList.add('js-toLeft');
					break;
				case 'wowAppearance':
					if (document.documentElement.clientWidth < 450 &&
					elem.classList.contains('c-about__img-wrap')) {
						elem.classList.add('js-toLeft');
						break;
					}
					elem.classList.add('js-wowAppearance');
					break;
				case 'simpleAppearance':
					elem.classList.add('js-simpleAppearance');
					break;
				case 'opacityAppearance':
					elem.classList.add('js-opacityAppearance');
					break;
				case 'toTop':
					elem.classList.add('js-toTop');
					break;
			}
		}
		
	}

		
	
	
	
	
})();